.css-f6r11q-marker.css-86nqku-diff-added pre,
.css-f6r11q-marker.css-v2oviu-diff-removed pre {
    display: none;
}
.css-ht5ywl-word-removed {
    background: #ffa8a8 !important;
    text-decoration: line-through;
}

.milestones-table li {
    margin: 0 10px;
}

.tox-notification {
    display: none !important;
}
.mce-edit-focus {
    outline: none;
}
